/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { ProjectLogo } from 'assets';
import { LegalLinks } from 'components';
import { FC, PropsWithChildren } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';

import './InvitationModal.scss';

type InvitationModalProps = {
  title: string;
} & PropsWithChildren;

export const InvitationModal: FC<InvitationModalProps> = ({
  title,
  children
}) => (
  <Modal
    centered
    backdrop={false}
    show
    fullscreen="sm-down"
    className="invitation-modal"
  >
    <Modal.Header className="border-bottom-0 text-center bg-white">
      <Container fluid>
        <Row>
          <Col className="pt-5">
            <ProjectLogo />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="mt-5 px-4">{title}</h2>
          </Col>
        </Row>
      </Container>
    </Modal.Header>
    <Modal.Body>
      <Container className="full-width-content px-4">{children}</Container>
    </Modal.Body>
    <LegalLinks />
  </Modal>
);
