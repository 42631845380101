/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRLoadingButton,
  SLRModal,
  SLRPrompt,
  SLRPromptProps,
  isEmptyOrNull,
  showErrorToast,
  useGenerateOrganizationKey
} from '@SLR/shared-library';
import { Organization } from '@SLR/user-service-full-sdk';
import { ENCRYPTION_DOWNLOAD_CONTAINER, SHOW_ENCRYPTION_PARAM } from 'configs';
import { useGetCurrentUserProfile } from 'hooks';
import { useQueryParam } from 'providers';
import { FC, useCallback, useState } from 'react';
import { Placeholder } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { KeyManagementToasts } from './KeyManagementToasts';
import { KeyManager } from './KeyManager';

type KeyManagementProps = {
  onOpenEdit: () => void;
  onClose: () => void;
  organization: Organization;
};

export const KeyManagement: FC<KeyManagementProps> = ({
  onOpenEdit,
  onClose,
  organization
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.details.keyManagement'
  });

  const { removeQueryParamsFromURL } = useQueryParam();

  const { data: user } = useGetCurrentUserProfile();
  const {
    isKeyLoading,
    isLoading,
    isCreated,
    isUpdated,
    isExpired,
    creationTime,
    expirationTime,
    fileName,
    publicKey,
    createKeys,
    updateKeys,
    deleteKeys,
    downloadKeys
  } = useGenerateOrganizationKey(
    organization,
    ENCRYPTION_DOWNLOAD_CONTAINER,
    t('keyFileNamePrefix')
  );

  const [promptData, setPromptData] = useState<SLRPromptProps | null>(null);

  const hidePrompt = useCallback(() => setPromptData(null), []);

  const hasKey = Boolean(publicKey);
  const isMissingEmail = isEmptyOrNull(organization.email);

  const handleCreate = useCallback(() => {
    if (!user || !organization) return;
    if (isMissingEmail) {
      showErrorToast('toasts.createOrganizationKey.missingEmailError');
      onClose();
      return;
    }
    createKeys({ onError: () => showErrorToast('toasts.unexpected.error') });
  }, [createKeys, isMissingEmail, onClose, organization, user]);

  const handleUpdate = useCallback(() => {
    setPromptData({
      labels: t('refresh', {
        returnObjects: true
      }),
      confirmButtonVariant: 'primary',
      onConfirm: () => {
        hidePrompt();
        updateKeys();
      },
      onCancel: hidePrompt
    });
  }, [hidePrompt, t, updateKeys]);

  const handleDownload = () => downloadKeys();

  const handleDelete = useCallback(() => {
    setPromptData({
      labels: t('delete', {
        returnObjects: true
      }),
      onConfirm: () => {
        hidePrompt();
        deleteKeys({
          onSuccess: () => {
            removeQueryParamsFromURL(SHOW_ENCRYPTION_PARAM, false);
            onClose();
          },
          onError: () => showErrorToast('toasts.unexpected.error')
        });
      },
      onCancel: hidePrompt
    });
  }, [deleteKeys, hidePrompt, onClose, removeQueryParamsFromURL, t]);

  return (
    <>
      {promptData && <SLRPrompt {...promptData} />}
      <SLRModal
        backdrop="static"
        title={t('title')}
        size="lg"
        fullscreen="md-down"
        isOpen
        onClose={onClose}
        isLoading={hasKey && isLoading}
      >
        <div className="key-management-modal p-sm-4">
          {isKeyLoading ? (
            <Placeholder animation="glow">
              <h2 className="mb-2">
                <Placeholder xs={5} />
              </h2>
              <p>
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={4} />
                <br />
                <br />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
              </p>
            </Placeholder>
          ) : (
            <>
              <h2 className="mb-2">
                {t(`${hasKey ? 'manage' : 'create'}.title`)}
              </h2>
              <p>
                <Trans
                  t={t}
                  i18nKey={`${hasKey ? 'manage' : 'create'}.description`}
                />
              </p>
              <KeyManagementToasts
                onOpenEdit={onOpenEdit}
                isMissingEmail={isMissingEmail}
                isCreated={isCreated}
                isUpdated={isUpdated}
                isExpired={isExpired}
              />
              {hasKey ? (
                <KeyManager
                  onRefresh={handleUpdate}
                  onDownload={handleDownload}
                  onDelete={handleDelete}
                  fileName={fileName}
                  isLoading={isLoading}
                  isMissingEmail={isMissingEmail}
                  isCreated={isCreated}
                  isUpdated={isUpdated}
                  isExpired={isExpired}
                  creationTime={creationTime}
                  expirationTime={expirationTime}
                />
              ) : (
                <SLRLoadingButton
                  disabled={isMissingEmail}
                  isLoading={isLoading}
                  onClick={handleCreate}
                  className="mt-4"
                  width="12.75rem"
                >
                  {t('button.create')}
                </SLRLoadingButton>
              )}
            </>
          )}
        </div>
      </SLRModal>
    </>
  );
};
