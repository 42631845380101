/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  showErrorToast,
  showSuccessToast,
  showWarningToast
} from '@SLR/shared-library';
import { GlobalErrorResponse, ResponseError } from '@SLR/user-service-full-sdk';
import { useMutation } from '@tanstack/react-query';
import { RESEND_INVITATION_NOT_POSSIBLE_ERROR } from 'configs';
import { useInvitationsApi } from 'services';

export const useResendInvitation = () => {
  const { resendInvitation } = useInvitationsApi();

  return useMutation({
    mutationFn: resendInvitation,
    onSuccess: () => showSuccessToast('toasts.resendInvitation.success'),
    onError: async (error) => {
      const ex: GlobalErrorResponse = await (
        error as ResponseError
      ).response.json();
      if (ex.uniqueExceptionType === RESEND_INVITATION_NOT_POSSIBLE_ERROR) {
        showWarningToast('toasts.resendInvitation.warning');
      } else {
        showErrorToast('toasts.resendInvitation.error');
      }
    }
  });
};
