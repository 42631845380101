/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRLoadingButton,
  SLRPrompt,
  getIcon,
  useAuth
} from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisableOtpForCurrentUser, useHasOtpCredentials } from 'hooks';
import { FC, useCallback, useMemo, useState } from 'react';
import { Badge, Placeholder } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

import './ProfileSecurity.scss';

export const ProfileSecurity: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'profilePage.security'
  });

  const { enableOTP } = useAuth();

  const { data: is2FAEnabled, isPending } = useHasOtpCredentials();
  const { mutate: disableOtp, isPending: isDisableOtpLoading } =
    useDisableOtpForCurrentUser();

  const [showPrompt, setShowPrompt] = useState(false);
  const openPrompt = useCallback(() => setShowPrompt(true), []);
  const closePrompt = useCallback(() => setShowPrompt(false), []);

  const activate2FA = useCallback(() => enableOTP(), [enableOTP]);

  const deactivate2FA = useCallback(
    () => disableOtp(undefined, { onSettled: closePrompt }),
    [closePrompt, disableOtp]
  );

  const toggle2FA = useCallback(
    () => (is2FAEnabled ? deactivate2FA() : activate2FA()),
    [is2FAEnabled, deactivate2FA, activate2FA]
  );

  const authState = useMemo(
    () => ({
      badgeLabel: t(is2FAEnabled ? 'active' : 'deactive'),
      message: t(is2FAEnabled ? 'disableText' : 'activateText'),
      button: (
        <SLRLoadingButton
          width="12rem"
          onClick={is2FAEnabled ? openPrompt : activate2FA}
          variant={is2FAEnabled ? 'outline-danger' : 'primary'}
          disabled={isPending || isDisableOtpLoading}
          isLoading={isDisableOtpLoading}
        >
          {isPending ? (
            <Placeholder xs={12} />
          ) : (
            <>
              {
                <FontAwesomeIcon
                  icon={getIcon('far', is2FAEnabled ? 'ban' : 'shield-check')}
                  className="me-2"
                />
              }
              {t(is2FAEnabled ? 'deactivate' : 'activate')}
            </>
          )}
        </SLRLoadingButton>
      )
    }),
    [activate2FA, is2FAEnabled, isDisableOtpLoading, isPending, openPrompt, t]
  );

  return (
    <>
      {showPrompt && (
        <SLRPrompt
          labels={{
            title: t('confirm.deactivate.title'),
            content: t('confirm.deactivate.content'),
            confirm: t('deactivate'),
            cancel: t('cancel')
          }}
          confirmButtonVariant="danger"
          onConfirm={toggle2FA}
          onCancel={closePrompt}
        />
      )}
      <div className="profile-security">
        <h1>{t('title')}</h1>
        <div>
          <div className="mt-5 d-flex align-items-center gap-3 flex-wrap flex-sm-nowrap">
            <h2 className="me-2">{t('heading')}</h2>
            <Badge
              pill
              className={`state-badge px-3 ${
                is2FAEnabled ? 'active' : 'inactive'
              }`}
            >
              {isPending ? <Placeholder xs={12} /> : authState.badgeLabel}
            </Badge>
          </div>
          <p className="mt-3 description">
            <Trans t={t} i18nKey="description" />
          </p>
          <p className="my-4 description">
            {isPending ? <Placeholder xs={12} /> : authState.message}
          </p>
          {authState.button}
        </div>
      </div>
    </>
  );
};
