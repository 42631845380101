/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  Breakpoint,
  getIcon,
  useAuth,
  useWindowSize
} from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ALLOWED_ROLES } from 'configs';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './ListAction.scss';

type ListActionProps = {
  id: string;
  currentRole: string;
  onDelete: (id: string) => void;
  onRoleChange: (id: string, role: string) => void;
  onResendInvitation?: (id: string) => void;
};

export const ListAction: FC<ListActionProps> = ({
  id,
  currentRole,
  onDelete,
  onRoleChange,
  onResendInvitation
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.details.listActions'
  });

  const { breakpoint } = useWindowSize();
  const { authProfile } = useAuth();

  const [show, setShow] = useState<boolean>(false);
  const [showSubmenu, setShowSubmenu] = useState<boolean>(false);

  const handleChangeRole = useCallback(
    (role: string) => () => {
      setShow(false);
      onRoleChange(id, role);
    },
    [id, onRoleChange]
  );

  const handleResendInvitation = useCallback(() => {
    if (onResendInvitation) {
      setShow(false);
      onResendInvitation(id);
    }
  }, [id, onResendInvitation]);

  const handleDelete = useCallback(() => {
    setShow(false);
    onDelete(id);
  }, [id, onDelete]);

  const drop = useMemo(() => {
    switch (breakpoint) {
      case Breakpoint.XL:
      case Breakpoint.XXL:
      case Breakpoint.XXXL:
        return 'end';
      default:
        return 'start';
    }
  }, [breakpoint]);

  return (
    <OverlayTrigger
      show={show}
      onToggle={() => setShow((prev) => !prev)}
      rootClose
      trigger="click"
      placement="left-start"
      overlay={
        <Popover className="list-action border-0 shadow px-0">
          <Popover.Body className="px-0">
            <Dropdown.Menu
              show
              align={{ md: 'end' }}
              className="border-0 shadow px-0"
            >
              {Boolean(onResendInvitation) && (
                <>
                  <Dropdown.Item
                    onClick={() => handleResendInvitation()}
                    className="resend"
                  >
                    <FontAwesomeIcon
                      icon={getIcon('fal', 'envelope')}
                      size="lg"
                      width={20}
                      className="me-2"
                    />
                    {t('resendInvitation')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>
              )}
              <DropdownButton
                className="list-action-sub-menu"
                title={
                  <>
                    <FontAwesomeIcon
                      icon={getIcon('fal', 'user-group')}
                      size="lg"
                      width={20}
                      className="me-2"
                    />
                    {t('changeRole')}
                  </>
                }
                drop={drop}
                align={{ xl: 'end' }}
                show={showSubmenu}
                onMouseEnter={() => setShowSubmenu(true)}
                onMouseLeave={() => setShowSubmenu(false)}
              >
                {ALLOWED_ROLES.filter((role) => role !== currentRole).map(
                  (role, index) => (
                    <Dropdown.Item
                      onClick={handleChangeRole(role)}
                      key={`subitem-${index}`}
                    >
                      {t(`${role as 'member' | 'admin'}`)}
                    </Dropdown.Item>
                  )
                )}
              </DropdownButton>
              {authProfile?.id !== id && (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={() => handleDelete()}
                    className="delete"
                  >
                    <FontAwesomeIcon
                      icon={getIcon('fal', 'times-circle')}
                      size="lg"
                      width={20}
                      className="me-2"
                    />
                    {t('delete')}
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Popover.Body>
        </Popover>
      }
    >
      <Button
        variant="link"
        className="list-action-button"
        title={t('menuLabel')}
      >
        <FontAwesomeIcon icon={getIcon('fal', 'ellipsis-stroke')} size="2x" />
      </Button>
    </OverlayTrigger>
  );
};
