/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRPrompt,
  SLRPromptProps,
  SLRSpinner,
  SLRTable,
  getIcon,
  hasValue,
  showErrorToast
} from '@SLR/shared-library';
import { Invitation } from '@SLR/user-service-full-sdk';
import {
  useChangeRole,
  useGetPendingOrganizationInvitations,
  useResendInvitation,
  useRevokeInvitation
} from 'hooks';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';
import { ListAction } from '.';

type InvitationsListProps = {
  organizationId?: string;
  isAdmin?: boolean;
};

export const InvitationsList: FC<InvitationsListProps> = ({
  organizationId,
  isAdmin
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.details'
  });

  const {
    data: invitations,
    isPending,
    isError
  } = useGetPendingOrganizationInvitations(organizationId ?? '');

  useEffect(() => {
    if (isError) {
      showErrorToast('toasts.loadInvitations.error');
    }
  }, [isError]);

  const { mutate: revokeInvitation } = useRevokeInvitation();
  const { mutate: changeRole } = useChangeRole();
  const { mutate: resendInvitation } = useResendInvitation();

  const [promptData, setPromptData] = useState<SLRPromptProps | null>(null);
  const hidePrompt = () => setPromptData(null);

  const getInvitedUser = useCallback(
    (id: string) => invitations?.find((i) => i.id === id)?.invitedUser,
    [invitations]
  );

  const handleResendInvitation = useCallback(
    (invitationId: string) => {
      if (organizationId) {
        resendInvitation({ organizationId, invitationId });
      }
    },
    [organizationId, resendInvitation]
  );

  const handleRemove = useCallback(
    (invitationId: string) => {
      setPromptData({
        confirmButtonVariant: 'danger',
        labels: t('removeInvitation', {
          returnObjects: true,
          name: getInvitedUser(invitationId)
        }),
        onConfirm: () => {
          if (organizationId) {
            revokeInvitation({ organizationId, invitationId });
          }
          hidePrompt();
        },
        onCancel: hidePrompt
      });
    },
    [getInvitedUser, organizationId, revokeInvitation, t]
  );

  const handleUpdateRole = useCallback(
    (invitationId: string, role: string) => {
      setPromptData({
        labels: t('updateInvitationRole', {
          returnObjects: true,
          name: getInvitedUser(invitationId),
          role: t(`${role as 'admin' | 'member'}`)
        }),
        onConfirm: () => {
          if (organizationId) {
            changeRole({
              organizationId,
              invitationId,
              changeRoleRequestDto: {
                role
              }
            });
          }
          hidePrompt();
        },
        onCancel: hidePrompt
      });
    },
    [t, getInvitedUser, organizationId, changeRole]
  );

  const columns: Column[] = useMemo(() => {
    const cols = [
      {
        Header: t('email'),
        accessor: isAdmin ? 'invitedUser' : ''
      },
      {
        Header: t('role'),
        accessor: 'role',
        Cell: ({ value }: { value: 'admin' | 'member' }) => t(`${value}`)
      }
    ] as Column[];

    if (isAdmin) {
      cols.push({
        Header: () => (
          <div className="d-none" aria-hidden>
            {t('listOptions')}
          </div>
        ),
        id: 'action',
        Cell: ({ row }: { row: Row }) => {
          const invitation = row.original as Invitation;
          return (
            <ListAction
              id={invitation.id}
              currentRole={invitation.role}
              onDelete={handleRemove}
              onRoleChange={handleUpdateRole}
              onResendInvitation={handleResendInvitation}
            />
          );
        }
      } as Column);
    }
    return cols;
  }, [t, isAdmin, handleRemove, handleUpdateRole, handleResendInvitation]);

  if (isPending) return <SLRSpinner height="5rem" />;

  return (
    <>
      {hasValue(invitations) && (
        <>
          {promptData && <SLRPrompt {...promptData} />}
          <Container className="table-responsive-md pt-6 pb-0">
            <SLRTable
              responsive="md"
              columns={columns}
              data={invitations}
              sortIcon={getIcon('fal', 'long-arrow-up')}
              tableHeaderProps={{
                title: t('invitationCount', {
                  count: invitations.length ?? 0
                })
              }}
            />
          </Container>
        </>
      )}
    </>
  );
};
