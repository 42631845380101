/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { handleError } from '@SLR/shared-library';
import {
  AcceptCurrentUserPendingInvitationRequest,
  AcceptInvitationRequest,
  ChangeRoleRequest,
  CheckInvitationRequest,
  CreateInvitationRequest,
  GetPendingInvitationsOfCurrentUserRequest,
  GetPendingInvitationsRequest,
  InvitationsApi,
  RejectCurrentUserPendingInvitationRequest,
  ResendInvitationRequest,
  RevokeInvitationRequest
} from '@SLR/user-service-full-sdk';
import { useCallback, useMemo } from 'react';
import { useGetUserServiceApiConfiguration } from './user.service';

export const useInvitationsApi = () => {
  const configuration = useGetUserServiceApiConfiguration();
  const apiInstance = useMemo(
    () => new InvitationsApi(configuration),
    [configuration]
  );

  const getPendingInvitations = useCallback(
    (requestParameters: GetPendingInvitationsRequest) =>
      apiInstance.getPendingInvitations(requestParameters).catch(handleError),
    [apiInstance]
  );

  const getPendingInvitationsOfCurrentUser = useCallback(
    (requestParameters: GetPendingInvitationsOfCurrentUserRequest) =>
      apiInstance
        .getPendingInvitationsOfCurrentUser(requestParameters)
        .catch(handleError),
    [apiInstance]
  );

  const acceptCurrentUserPendingInvitation = useCallback(
    (requestParameters: AcceptCurrentUserPendingInvitationRequest) =>
      apiInstance
        .acceptCurrentUserPendingInvitation(requestParameters)
        .catch(handleError),
    [apiInstance]
  );

  const acceptInvitation = useCallback(
    (requestParameters: AcceptInvitationRequest) =>
      apiInstance.acceptInvitation(requestParameters).catch(handleError),
    [apiInstance]
  );

  const rejectCurrentUserPendingInvitation = useCallback(
    (requestParameters: RejectCurrentUserPendingInvitationRequest) =>
      apiInstance
        .rejectCurrentUserPendingInvitation(requestParameters)
        .catch(handleError),
    [apiInstance]
  );

  const createInvitation = useCallback(
    (requestParameters: CreateInvitationRequest) =>
      apiInstance.createInvitation(requestParameters).catch(handleError),
    [apiInstance]
  );

  const changeRole = useCallback(
    (requestParameters: ChangeRoleRequest) =>
      apiInstance.changeRole(requestParameters).catch(handleError),
    [apiInstance]
  );
  const revokeInvitation = useCallback(
    (requestParameters: RevokeInvitationRequest) =>
      apiInstance.revokeInvitation(requestParameters).catch(handleError),
    [apiInstance]
  );

  const resendInvitation = useCallback(
    (requestParameters: ResendInvitationRequest) =>
      apiInstance.resendInvitation(requestParameters).catch(handleError),
    [apiInstance]
  );

  const checkInvitation = useCallback(
    (requestParameters: CheckInvitationRequest) =>
      apiInstance
        .checkInvitation(requestParameters)
        .then(() => true)
        .catch(handleError),
    [apiInstance]
  );

  return {
    getPendingInvitations,
    getPendingInvitationsOfCurrentUser,
    acceptCurrentUserPendingInvitation,
    acceptInvitation,
    rejectCurrentUserPendingInvitation,
    createInvitation,
    changeRole,
    revokeInvitation,
    resendInvitation,
    checkInvitation
  };
};
