/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  getFormattedDate,
  getFormattedTime,
  getIcon
} from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ENCRYPTION_DOWNLOAD_CONTAINER } from 'configs';
import { FC } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const DateTimeDisplay: FC<{
  date: Date | null | undefined;
  localizationKey: `createdOn` | `expiresOn`;
  className?: string;
}> = ({ date, localizationKey, className = 'text-muted' }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.details.keyManagement'
  });

  if (!date) return null;

  return (
    <div className={`fs-6 ${className}`}>
      {t(localizationKey, {
        date: getFormattedDate(date),
        time: getFormattedTime(date)
      })}
    </div>
  );
};

type KeyManagerProps = {
  fileName: string;
  isLoading: boolean;
  isMissingEmail: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isExpired: boolean;
  creationTime: Date | null | undefined;
  expirationTime: Date | null | undefined;
  onRefresh: () => void;
  onDownload: () => void;
  onDelete: () => void;
};

export const KeyManager: FC<KeyManagerProps> = ({
  fileName,
  isLoading,
  isMissingEmail,
  isCreated,
  isUpdated,
  creationTime,
  expirationTime,
  isExpired,
  onRefresh,
  onDownload,
  onDelete
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.details.keyManagement'
  });

  return (
    <Card className="shadow border-0 mt-2">
      <Card.Body className="py-4 px-3 px-sm-4 px-lg-5">
        <Row>
          <Col xs="auto" className="mx-auto mb-2">
            <FontAwesomeIcon
              icon={getIcon('fal', 'key')}
              size="3x"
              className="text-muted"
            />
          </Col>
          <Col>
            <Row>
              <Col className="mb-4">
                <h5 className="text-black">{fileName}</h5>
                <DateTimeDisplay
                  date={creationTime}
                  localizationKey="createdOn"
                />
                <DateTimeDisplay
                  date={expirationTime}
                  localizationKey="expiresOn"
                  {...(isExpired ? { className: 'text-danger' } : {})}
                />
              </Col>
            </Row>
            <Row className="key-actions justify-content-start">
              {(isCreated || isUpdated) && (
                <Col xs="12" sm="auto" md="12" lg="auto" className="mb-2 pe-0">
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    onClick={onDownload}
                  >
                    <FontAwesomeIcon
                      icon={getIcon('fal', 'download')}
                      size="lg"
                      className="pe-2"
                    />
                    {t('button.download')}
                  </Button>
                  <div id={ENCRYPTION_DOWNLOAD_CONTAINER}></div>
                </Col>
              )}
              <Col xs="auto" className="mb-2 pe-0">
                <Button
                  variant="outline-primary"
                  disabled={isLoading || isMissingEmail}
                  onClick={onRefresh}
                >
                  <FontAwesomeIcon
                    icon={getIcon('fal', 'arrows-rotate')}
                    size="lg"
                    className="pe-2"
                  />
                  {t('button.refresh')}
                </Button>
              </Col>
              <Col xs="auto" className="mb-2 pe-0">
                <Button
                  variant="outline-danger"
                  disabled={isLoading || isMissingEmail}
                  onClick={onDelete}
                >
                  <FontAwesomeIcon
                    icon={getIcon('fal', 'trash-can')}
                    size="lg"
                    className="pe-2"
                  />
                  {t('button.delete')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
