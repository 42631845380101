/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRTooltip, getIcon } from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProjectLogo } from 'assets';
import { LegalLinks } from 'components';
import { FC, PropsWithChildren } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './ChooseOrganizationModal.scss';

type ChooseOrganizationModalProps = {
  solutionUrl: string;
  solutionName: string;
  title: string;
} & PropsWithChildren;

export const ChooseOrganizationModal: FC<ChooseOrganizationModalProps> = ({
  solutionUrl,
  solutionName,
  title,
  children
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chooseOrganizationPage'
  });

  return (
    <Modal
      centered
      backdrop={false}
      show
      fullscreen="sm-down"
      className="choose-organization-modal"
    >
      <Modal.Header className="border-bottom-0 bg-white">
        <Container fluid>
          <Row>
            <Col xs="2" className="pt-4">
              <Link
                to={solutionUrl}
                className="rounded-circle back-button btn btn-outline-primary btn-lg"
                replace
                title={t('navigateTo', { solution: solutionName })}
              >
                <FontAwesomeIcon icon={getIcon('far', 'arrow-left')} />
              </Link>
            </Col>
            <Col xs="9" className="text-center pt-5">
              <ProjectLogo />
            </Col>
            <Col xs="1" className="text-end">
              <SLRTooltip
                content={
                  <span>
                    {t('help', {
                      solution: solutionName
                    })}
                  </span>
                }
                trigger={['hover', 'focus']}
                placement="auto"
              >
                <FontAwesomeIcon
                  icon={getIcon('far', 'question-circle')}
                  width={25}
                  className="text-primary cursor-pointer"
                  size="lg"
                />
              </SLRTooltip>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5 text-center">
              <h2>{title}</h2>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      {children}
      <LegalLinks />
    </Modal>
  );
};
