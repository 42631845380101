/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { handleError } from '@SLR/shared-library';
import {
  CreateDataPrivacyReportRequest,
  DataPrivacyApi,
  DeleteUserAccountRequest
} from '@SLR/user-service-full-sdk';
import { useCallback, useMemo } from 'react';
import { useGetUserServiceApiConfiguration } from './user.service';

export const useDataPrivacyApi = () => {
  const configuration = useGetUserServiceApiConfiguration();
  const apiInstance = useMemo(
    () => new DataPrivacyApi(configuration),
    [configuration]
  );

  const createDataPrivacyReport = useCallback(
    (requestParameters: CreateDataPrivacyReportRequest) =>
      apiInstance.createDataPrivacyReport(requestParameters).catch(handleError),
    [apiInstance]
  );

  const deleteUserAccount = useCallback(
    (requestParameters: DeleteUserAccountRequest) =>
      apiInstance.deleteUserAccount(requestParameters).catch(handleError),
    [apiInstance]
  );

  return {
    createDataPrivacyReport,
    deleteUserAccount
  };
};
