/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { getValue, isEmptyOrNull, useAuth } from '@SLR/shared-library';
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from 'configs';
import { t } from 'i18next';

export const useRequestToken = () => {
  const { authProfile } = useAuth();

  const requestToken = async (
    password?: string,
    otpEnabled?: boolean,
    totp?: string
  ): Promise<string> => {
    const url = `${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`;

    const invalidCredentialsErrorMessage = t(
      otpEnabled
        ? 'toasts.emailChange.invalidCredentialsOrOtp'
        : 'toasts.emailChange.invalidCredentials'
    );

    if (isEmptyOrNull(authProfile?.username)) {
      throw new Error('Username not set in token!');
    }

    if (isEmptyOrNull(password) || (otpEnabled && isEmptyOrNull(totp))) {
      throw new Error(invalidCredentialsErrorMessage);
    }

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('client_id', KEYCLOAK_CLIENT_ID);
    urlencoded.append('username', authProfile?.username ?? '');
    urlencoded.append('password', password ?? '');
    urlencoded.append('grant_type', 'password');
    if (otpEnabled) urlencoded.append('totp', totp ?? '');

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    if (response.ok) {
      const passwordToken = await response.json();
      return getValue('access_token', passwordToken) ?? '';
    }

    throw new Error(invalidCredentialsErrorMessage);
  };

  return { requestToken };
};
