/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { DEFAULT_PROFILE_IMAGE_PATH } from 'configs';
import { FC } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type HandleProps = {
  userName: string;
  userEmail: string;
  userPicture?: string;
  userPictureAlt?: string;
  organizationName: string;
  onCurrentAccountAcceptClick: () => void;
  onDifferentAccountAcceptClick: () => void;
};

export const Handle: FC<HandleProps> = ({
  userName,
  userEmail,
  userPicture = DEFAULT_PROFILE_IMAGE_PATH,
  userPictureAlt,
  organizationName,
  onCurrentAccountAcceptClick,
  onDifferentAccountAcceptClick
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'invitationsPage.handle'
  });

  return (
    <>
      <Row>
        <Col>
          <p className="text-left">
            {t('description', {
              organization: organizationName
            })}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-left mt-5 shadow p-3 bg-body rounded">
            <Row className="d-flex align-items-center">
              <Col className="profile-picture">
                <Image
                  src={userPicture}
                  alt={userPictureAlt ?? t('userPictureAlt')}
                  className="profile-picture"
                  roundedCircle
                />
              </Col>
              <Col className="ms-2">
                <Row>
                  <p className="user-name">{userName}</p>
                </Row>
                <Row>
                  <p className="user-email">{userEmail}</p>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-6 mb-4">
          <Button
            size="lg"
            variant="primary"
            className="w-100"
            onClick={onCurrentAccountAcceptClick}
          >
            {t('acceptCurrentAccount')}
          </Button>
          <Button
            size="lg"
            variant="outline-primary"
            className="mt-2 w-100"
            onClick={onDifferentAccountAcceptClick}
          >
            {t('acceptDifferentAccount')}
          </Button>
        </Col>
      </Row>
    </>
  );
};
