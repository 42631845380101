/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRLoadingButton, SLRPrompt } from '@SLR/shared-library';
import { SUPPORT_URL } from 'configs';
import {
  useCreateDataPrivacyReport,
  useDeleteUserAccount,
  useGetCurrentUserProfile
} from 'hooks';
import { FC, useCallback, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './ProfileDataPrivacy.scss';

export const ProfileDataPrivacy: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'profilePage.dataPrivacy'
  });

  const { data: user } = useGetCurrentUserProfile();

  const userId = useMemo(() => user?.id ?? '', [user?.id]);

  const [showPrompt, setShowPrompt] = useState(false);
  const openConfirmationDialog = useCallback(() => setShowPrompt(true), []);
  const closeConfirmationDialog = useCallback(() => setShowPrompt(false), []);

  const {
    mutate: requestDataPrivacyReport,
    isPending: isDataPrivacyReportLoading
  } = useCreateDataPrivacyReport();
  const { mutate: deleteUserAccount, isPending: isDeleteAccountLoading } =
    useDeleteUserAccount();

  const handleRequestDataReport = useCallback(
    () => requestDataPrivacyReport({ userId }),
    [requestDataPrivacyReport, userId]
  );

  const handleDeleteUserAccount = useCallback(() => {
    deleteUserAccount({ userId }, { onSettled: closeConfirmationDialog });
  }, [closeConfirmationDialog, deleteUserAccount, userId]);

  return (
    <>
      {showPrompt && (
        <SLRPrompt
          isLoading={isDeleteAccountLoading}
          labels={t('deleteConfirmation', {
            returnObjects: true
          })}
          confirmButtonVariant="danger"
          onConfirm={handleDeleteUserAccount}
          onCancel={closeConfirmationDialog}
        />
      )}
      <div className="profile-data-privacy">
        <h1>{t('title')}</h1>

        <div className="mt-5">
          <h2>{t('requestReport')}</h2>

          <p className="mt-3 mb-4 description">
            <Trans t={t} i18nKey="requestDescription" />
          </p>

          <SLRLoadingButton
            isLoading={isDataPrivacyReportLoading}
            width="16rem"
            onClick={handleRequestDataReport}
          >
            {t('requestReport')}
          </SLRLoadingButton>
        </div>

        <div className="mt-5">
          <h2>{t('deleteAccount')}</h2>

          <p className="mt-3 mb-4 description">
            <Trans
              t={t}
              i18nKey="deleteDescription"
              components={[
                <Link target="_blank" to={SUPPORT_URL} key="SUPPORT_URL" />
              ]}
            />
          </p>
          <Button
            size="lg"
            onClick={openConfirmationDialog}
            variant="outline-danger"
          >
            {t('deleteAccount')}
          </Button>
        </div>
      </div>
    </>
  );
};
