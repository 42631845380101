/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRLoadingButton, hasValue } from '@SLR/shared-library';
import { LockLogo } from 'assets';
import { useGetCurrentUserProfile, useSendUpdatePasswordEmail } from 'hooks';
import { FC, useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './ProfilePasswordChange.scss';

export const ProfilePasswordChange: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'profilePage.password'
  });

  const { data } = useGetCurrentUserProfile();
  const socialProviderNames = useMemo(
    () =>
      data?.identityProviders?.map(
        (identityProvider) => identityProvider.name ?? 'unknown'
      ),
    [data?.identityProviders]
  );

  const { mutate: updatePassword, isPending: isUpdatePasswordLoading } =
    useSendUpdatePasswordEmail();

  const handleUpdatePassword = useCallback(
    () => updatePassword(),
    [updatePassword]
  );

  return (
    <div className="profile-password-change">
      <h1>{t('title')}</h1>

      <Row>
        <Col sm="12" md="9" lg="9">
          <p className="mt-md-5 mt-4 mb-5 description">
            {hasValue(socialProviderNames)
              ? t('identityProvider', {
                  identityProvider: socialProviderNames.join(
                    ` ${t('connectingWord')} `
                  )
                })
              : t('default')}
          </p>
        </Col>
        <Col className="d-none d-md-block">
          <LockLogo width="100%" />
        </Col>
      </Row>

      {!hasValue(socialProviderNames) && (
        <SLRLoadingButton
          isLoading={isUpdatePasswordLoading}
          width="15.25rem"
          onClick={handleUpdatePassword}
        >
          {t('reset')}
        </SLRLoadingButton>
      )}
    </div>
  );
};
