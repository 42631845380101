/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  handleError,
  isEmptyOrNull,
  showErrorToast
} from '@SLR/shared-library';
import {
  Configuration,
  GlobalErrorResponse,
  ResponseError,
  UserProfile,
  UsersApi
} from '@SLR/user-service-full-sdk';
import { useMutation } from '@tanstack/react-query';
import { API_USER_SERVICE_URL, queryClient, queryKeys } from 'configs';
import { useRequestToken } from 'hooks';
import { t } from 'i18next';
import { useUsersApi } from 'services';

const changeEmail = (accessToken: string, newEmailAddress: string) =>
  new UsersApi(
    new Configuration({
      basePath: API_USER_SERVICE_URL,
      accessToken: `Bearer ${accessToken}`
    })
  )
    .changeEmailAddress({
      changeEmailAddressRequestDto: {
        newEmailAddress
      }
    })
    .catch(handleError);

export const useChangeEmailAddress = (otpEnabled: boolean) => {
  const { changeEmailAddress } = useUsersApi();
  const { requestToken } = useRequestToken();

  const invalidCredentialsErrorMessage = t(
    otpEnabled
      ? 'toasts.emailChange.invalidCredentialsOrOtp'
      : 'toasts.emailChange.invalidCredentials'
  );

  return useMutation<
    UserProfile,
    Error | ResponseError,
    {
      newEmailAddress: string;
      currentPassword?: string;
      usesSocialLogin?: boolean;
      otpEnabled?: boolean;
      totp?: string;
    }
  >({
    mutationFn: async ({
      newEmailAddress,
      currentPassword,
      totp = '',
      usesSocialLogin = false
    }) => {
      if (usesSocialLogin) {
        return changeEmailAddress({
          changeEmailAddressRequestDto: { newEmailAddress }
        });
      } else {
        const accessToken = await requestToken(
          currentPassword,
          otpEnabled,
          totp
        );

        if (isEmptyOrNull(accessToken)) {
          throw new Error(invalidCredentialsErrorMessage);
        }

        return changeEmail(accessToken, newEmailAddress);
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getCurrentUserProfile]
      }),
    onError: async (error, { newEmailAddress }) => {
      if (error instanceof ResponseError) {
        const ex: GlobalErrorResponse = await (
          error as ResponseError
        ).response.json();

        showErrorToast({
          title: t('toasts.emailChange.error.title'),
          content: ex.message
            ?.toString()
            .includes('already used by another user')
            ? t('toasts.emailChange.error.content', {
                email: newEmailAddress
              })
            : undefined
        });
      } else {
        showErrorToast({
          title: t('toasts.unexpected.error.title'),
          content:
            error.message === invalidCredentialsErrorMessage
              ? error.message
              : t('toasts.unexpected.error.content')
        });
      }
    }
  });
};
