/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRScrollToTop,
  SLRToastContainer,
  useClearToasts
} from '@SLR/shared-library';
import { ErrorView } from 'components';
import { useQueryParam } from 'providers';
import { FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import { Footer, NavBar, SolutionBar } from '.';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  useClearToasts();

  const { pathname } = useLocation();
  const { showLayout, showSolutionBar } = useQueryParam();

  const childrenView = (
    <ErrorBoundary key={pathname} fallback={<ErrorView />}>
      {children}
    </ErrorBoundary>
  );

  return showLayout ? (
    <div className="d-flex flex-column bg-white" style={{ minHeight: '100vh' }}>
      <SLRToastContainer position="top-end" className="mt-7" />

      <SLRScrollToTop />

      <header className="flex-shrink-0">
        {showSolutionBar && <SolutionBar />}
        <NavBar />
      </header>
      <main className="d-grid flex-grow-1">{childrenView}</main>
      <footer className="flex-shrink-0">
        <Footer />
      </footer>
    </div>
  ) : (
    childrenView
  );
};
