/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRFormInfo, getIcon, hasValue } from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter } from 'components';
import { useChangeEmailAddress, useGetCurrentUserProfile } from 'hooks';
import { FC, useCallback, useMemo, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EmailFormInput, EmailFormInputSchema, ProfileToasts } from '..';

export const ProfileEmailChange: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'profilePage.email'
  });

  const { data: user } = useGetCurrentUserProfile();

  const { emailAddress, pendingNewEmailAddress } = user ?? {};
  const usesSocialLogin = hasValue(user?.identityProviders);
  const otpEnabled = Boolean(user?.otpEnabled);

  const { mutate: changeEmailAddress, isPending } =
    useChangeEmailAddress(otpEnabled);

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    register,
    reset
  } = useForm<EmailFormInput>({
    resolver: yupResolver(
      EmailFormInputSchema(
        usesSocialLogin,
        otpEnabled,
        emailAddress,
        pendingNewEmailAddress
      )
    )
  });

  const handleFormSubmit = useCallback(
    (data: EmailFormInput) => {
      changeEmailAddress(
        {
          newEmailAddress: data.newEmail,
          currentPassword: data.password,
          totp: data.totp,
          usesSocialLogin
        },
        { onSuccess: () => reset() }
      );
    },
    [changeEmailAddress, reset, usesSocialLogin]
  );

  const [passwordFieldType, setPasswordFieldType] = useState<
    'password' | 'text'
  >('password');

  const isPasswordField = useMemo(
    () => passwordFieldType === 'password',
    [passwordFieldType]
  );

  return (
    <>
      <ProfileToasts />
      <Form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Row className="mb-4 mb-md-5">
          <Col xs="12" md="8" lg="8">
            <h1 className="mb-3 mb-md-5 ">{t('title')}</h1>
            <div>
              <Form.Label htmlFor="email">{t('email')}*</Form.Label>
              <Form.Control
                id="email"
                type="text"
                autoComplete="true"
                placeholder={t('new')}
                className="form-control-lg"
                isInvalid={!!errors?.newEmail}
                {...register('newEmail')}
              />
              <SLRFormInfo
                text={errors?.newEmail?.message}
                isInvalid={!!errors?.newEmail}
              />
            </div>
            {!usesSocialLogin && (
              <div className="mt-4">
                <Form.Label htmlFor="password">{t('password')}*</Form.Label>
                <InputGroup>
                  <Form.Control
                    id="password"
                    type={passwordFieldType}
                    className="form-control-lg border-end-0"
                    isInvalid={!!errors?.password}
                    {...register('password')}
                  />
                  <InputGroup.Text
                    className={`${
                      errors.password ? 'border-danger' : ''
                    } border-start-0 cursor-pointer`}
                    onClick={() =>
                      setPasswordFieldType(
                        isPasswordField ? 'text' : 'password'
                      )
                    }
                  >
                    <FontAwesomeIcon
                      className="text-primary"
                      width={20}
                      icon={getIcon(
                        'fas',
                        isPasswordField ? 'eye' : 'eye-slash'
                      )}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <SLRFormInfo
                  text={t('passwordRequired')}
                  isInvalid={!!errors?.password}
                />
              </div>
            )}
            {otpEnabled && (
              <div className="mt-4">
                <Form.Label htmlFor="totp">{t('totp')}</Form.Label>
                <Form.Control
                  id="totp"
                  type="text"
                  className="form-control-lg"
                  isInvalid={!!errors?.totp}
                  {...register('totp')}
                />
                <SLRFormInfo
                  text={t('totpRequired')}
                  isInvalid={!!errors?.totp}
                />
              </div>
            )}
          </Col>
        </Row>
        <FormFooter
          isLoading={isPending}
          isDisabled={!hasValue(Object.keys(dirtyFields))}
        />
      </Form>
    </>
  );
};
